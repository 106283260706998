import { template as template_1e9e1f73a44f4eb4901688d7350bbb60 } from "@ember/template-compiler";
const FKText = template_1e9e1f73a44f4eb4901688d7350bbb60(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
