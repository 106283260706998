import { template as template_2dda2e4de6824659aa4e33ea72c130bd } from "@ember/template-compiler";
const FKLabel = template_2dda2e4de6824659aa4e33ea72c130bd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
