import { template as template_22efa3cbfb54459b8a59ec45c3694007 } from "@ember/template-compiler";
const FKControlMenuContainer = template_22efa3cbfb54459b8a59ec45c3694007(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
